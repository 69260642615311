

.single-char {
    margin-top: 50px;
    display: grid;
    grid-template-columns: 430px 550px;
    column-gap: 50px;
    align-items: start;
    img {
        width: 380px;
        
    }
    &__name {
        font-weight: bold;
        font-size: 22px;
        line-height: 29px;
    }
    &__descr {
        font-size: 18px;
        line-height: 24px;
        margin-top: 25px;
    }
}